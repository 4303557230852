.p-categoriesheader {
  padding: 40px 0;
  text-align: center;
}

.p-projectheader {
  padding: 40px 0 55px;
  text-align: center;
}

.p-projectcategories {
  background: #ffffff;
  padding: 40px 0;
}

.p-projectcategory {
  background: #ffffff;
}
.p-projectcategory .p-projects {
  display: flex;
  flex-wrap: wrap;
  margin-top: -15px;
  margin-left: -15px;
  padding: 40px 0;
}
.p-projectcategory .p-projects .p-projects__project {
  flex: 1 0 200px;
  margin-top: 15px;
  margin-left: 15px;
}
@media (min-width: 430px) {
  .p-projectcategory .p-projects .p-projects__project {
    max-width: calc(50% - 15px);
  }
}
@media (min-width: 400px) {
  .p-projectcategory .p-projects .p-projects__project {
    min-width: calc(50% - 15px);
  }
}
.p-projectcategory .p-projects__project {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 40px;
}
.p-projectcategory .p-projects__image {
  display: block;
  width: 100%;
}
.p-projectcategory .p-projects__title {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 10px 0 0;
  height: 70px;
  margin: -70px 0 0 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
}
.p-projectcategory .p-projects__link {
  position: absolute;
  bottom: -19px;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #6B502C;
  background: #9f7742;
  color: #ffffff;
  height: 38px;
  padding: 0 30px;
  line-height: 38px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  transition: background 0.3s, color 0.3s, border 0.3s;
}
.p-projectcategory .p-projects__link:hover {
  background: #6B502C;
}
@media screen and (max-width: 768px) {
  .p-projectcategory .p-projects {
    display: block;
  }
  .p-projectcategory .p-projects .p-projects__project {
    max-width: none;
  }
}

@media screen and (max-width: 768px) {
  .p-projectcategory {
    padding: 0 25px;
  }
}
.p-project {
  margin: 0 0 40px;
}
.p-project .p-title {
  padding: 40px 0;
  text-align: center;
}