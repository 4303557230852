html, body, form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

datalist, template {
  display: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

button {
  border: 0;
  border-radius: 0;
  background: none;
  cursor: pointer;
}

.wh-form__label {
  display: block;
  margin-bottom: 4px;
}
.wh-form__fields {
  max-width: 600px;
}
.wh-form__group {
  margin: 0 0 30px;
}
.wh-form__fieldgroup {
  align-items: center;
  margin: 0 0 20px;
}
.wh-form__fieldgroup--error .wh-form__optionlabel {
  color: #d00;
}
.wh-form__fieldgroup--required label::after {
  content: "*";
}
.wh-form__fieldgroup .wh-form__prefix {
  margin-right: 10px;
}
.wh-form__fieldgroup .wh-form__suffix {
  margin-left: 10px;
}
.wh-form input[type=text],
.wh-form input[type=email],
.wh-form input[type=password],
.wh-form input[type=url],
.wh-form input[type=search],
.wh-form input[type=number],
.wh-form input[type=date],
.wh-form input[type=file],
.wh-form textarea {
  font-size: 16px;
  padding: 20px 10px;
  width: 100%;
  color: #000;
  border: 1px solid #6B502C;
}
.wh-form input[type=text].parsley-error,
.wh-form input[type=email].parsley-error,
.wh-form input[type=password].parsley-error,
.wh-form input[type=url].parsley-error,
.wh-form input[type=search].parsley-error,
.wh-form input[type=number].parsley-error,
.wh-form input[type=date].parsley-error,
.wh-form input[type=file].parsley-error,
.wh-form textarea.parsley-error {
  border-color: #d00;
}
.wh-form input[type=text]::placeholder,
.wh-form input[type=email]::placeholder,
.wh-form input[type=password]::placeholder,
.wh-form input[type=url]::placeholder,
.wh-form input[type=search]::placeholder,
.wh-form input[type=number]::placeholder,
.wh-form input[type=date]::placeholder,
.wh-form input[type=file]::placeholder,
.wh-form textarea::placeholder {
  color: #cccccc;
}
.wh-form input[type=text][disabled],
.wh-form input[type=email][disabled],
.wh-form input[type=password][disabled],
.wh-form input[type=url][disabled],
.wh-form input[type=search][disabled],
.wh-form input[type=number][disabled],
.wh-form input[type=date][disabled],
.wh-form input[type=file][disabled],
.wh-form textarea[disabled] {
  background: rgba(238, 238, 238, 0.5);
  color: rgba(0, 0, 0, 0.3);
}
.wh-form input[type=number] {
  max-width: 150px;
}
.wh-form textarea {
  height: 150px;
  padding-top: 10px;
}
.wh-form select {
  height: 42px;
}
.wh-form__fieldgroup--radiogroup .wh-form__optiondata, .wh-form__fieldgroup--checkboxgroup .wh-form__optiondata, .wh-form__fieldgroup--checkbox .wh-form__optiondata {
  margin: 0 15px 0 5px;
}
.wh-form__fieldgroup--radiogroup .wh-form__subfieldlabel + input, .wh-form__fieldgroup--pulldown .wh-form__subfieldlabel + input {
  margin-left: 10px;
}
.wh-form__fieldgroup--radiogroup .wh-form__fieldline {
  margin-bottom: 8px;
}
.wh-form__buttongroup .wh-form__button {
  margin-right: 10px;
}
.wh-form .wh-form__fields,
.wh-form .wh-form__fieldgroup--imgedit .wh-form__fieldline {
  display: flex;
  flex-direction: column;
}
.wh-form .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--imgedit .wh-form__imgedit {
  order: 2;
}
.wh-form .wh-form__errorlist,
.wh-form .wh-form__fieldgroup--imgedit .parsley-errors-list {
  order: 1;
  margin: 0 0 6px;
}
.wh-form .parsley-errors-list {
  width: 100%;
  padding: 0;
  margin: 0;
  color: #d00;
  font: inherit;
  list-style-type: none;
}
.wh-form .wh-form__fieldgroup--imgedit .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--imgedit .wh-form__imgedit {
  display: inline-block;
}
.wh-form__imgedit {
  outline: none;
}
.wh-form__imgedit .wh-form__imgeditholder {
  display: inline-block;
  position: relative;
  border: 1px solid black;
  width: 100px;
  height: 100px;
}
.wh-form__imgedit .wh-form__imgeditholder::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  font-size: 24px;
  content: "\f03e";
  font-family: FontAwesome;
  height: auto;
}
.wh-form__imgedit--hasimage .wh-form__imgeditholder {
  width: 250px;
  height: 250px;
}
.wh-form__imgedit--hasimage .wh-form__imgeditholder::before {
  display: none;
}
.wh-form__imgeditimg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.wh-form__imgeditdelete::after {
  color: #d00;
  content: "Afbeelding verwijderen";
  display: block;
  font-size: 0.8em;
  cursor: pointer;
}
.wh-form__button {
  display: inline-block;
  background: #9f7742;
  border: 1px solid #6B502C;
  padding: 10px 15px;
  font-size: 16px;
  transition: all 0.3s;
  color: #ffffff;
  transition: background 0.3s, color 0.3s, border 0.3s;
}
.wh-form__button:hover {
  background: #6B502C;
  color: #ffffff;
}

.newsitems__categories {
  margin: 20px 0;
}

.newsitems__category {
  display: inline-block;
  text-decoration: none;
  border: 1px solid #000;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 15px;
  transition: background-color 0.3s, color 0.3s;
}
.newsitems__category--selected, .newsitems__category:hover {
  background-color: #0d3579;
  color: #fff;
}

.news__items {
  list-style-type: none;
  margin: 25px 0 0;
  padding: 0;
}
.news__item-li {
  margin-bottom: 40px;
}
.news__item {
  margin: 10px 0 25px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
.news__image-container {
  width: 25%;
}
.news__image {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s;
}
.news__image-link {
  overflow: hidden;
  display: block;
}
.news__image-link:hover .news__image {
  transform: scale(1.1);
}
.news__info {
  width: 75%;
  padding-left: 25px;
}
.news__date {
  margin-bottom: 10px;
}
.news__heading {
  margin-top: 0;
}
.news__readmore-container {
  text-align: right;
  margin-top: 10px;
}
.news--small .news__summary,
.news--small .news__readmore-container {
  display: none;
}
.news--small .news__item,
.news--small .news__item-li {
  margin-bottom: 0;
}
.news--small .news__image-container {
  width: 20%;
}
.news--small .news__info {
  width: 80%;
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .news__image-container, .news--small .news__image-container {
    width: 100%;
  }
  .news__info, .news--small .news__info {
    width: 100%;
    padding-left: 0;
  }
}

.newsdetails__image {
  margin: 0 0 15px;
}
.newsdetails__image img {
  max-height: 200px;
  width: auto;
}
.newsdetails__back {
  margin-bottom: 10px;
}
.newsdetails__date {
  margin-bottom: 20px;
}
.newsdetails__addthis {
  margin: 0 0 25px;
}

.emb-news-ctas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  flex: 1;
}
.emb-news-ctas__col {
  width: calc(33.3333333333% - 40px);
  display: flex;
  flex-direction: column;
  background-clip: content-box;
  margin-bottom: 30px;
}
.emb-news-ctas__col--image {
  max-width: 100%;
  height: 310px;
  object-fit: cover;
}
.emb-news-ctas__col--text h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
.emb-news-ctas__col--text p {
  overflow: hidden;
  line-height: 25px;
  margin-bottom: 25px;
}
.emb-news-ctas__col--button {
  align-self: flex-start;
  margin-top: auto;
}
@media screen and (max-width: 1024px) {
  .emb-news-ctas__col {
    width: calc(50% - 40px);
  }
  .emb-news-ctas__col .button {
    max-width: unset;
  }
}
@media screen and (max-width: 767px) {
  .emb-news-ctas__col {
    width: 100%;
    margin: 40px 0 0 0;
  }
  .emb-news-ctas__col--text p {
    height: auto;
  }
}

@supports (display: grid) {
  html.use-grid-css .emb-news-ctas {
    display: grid;
    grid-gap: 35px 25px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  html.use-grid-css .emb-news-ctas__col {
    width: auto;
  }
  @media screen and (max-width: 1024px) {
    html.use-grid-css .emb-news-ctas {
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    }
  }
  @media screen and (max-width: 767) {
    html.use-grid-css .emb-news-ctas {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }
}
@supports not (display: grid) {
  html.use-grid-css .emb-news-ctas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    flex: 1;
  }
}
html.use-grid-css .emb-news-ctas__col {
  display: flex;
  flex-direction: column;
  background-clip: content-box;
  margin-bottom: 30px;
}
@supports not (display: grid) {
  html.use-grid-css .emb-news-ctas__col {
    width: calc(33.3333333333% - 40px);
  }
}
html.use-grid-css .emb-news-ctas__col--image {
  max-width: 100%;
  height: 310px;
  object-fit: cover;
}
html.use-grid-css .emb-news-ctas__col--text h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
html.use-grid-css .emb-news-ctas__col--text p {
  overflow: hidden;
  line-height: 25px;
  margin-bottom: 25px;
}
html.use-grid-css .emb-news-ctas__col--button {
  align-self: flex-start;
  margin-top: auto;
}
@supports not (display: grid) {
  @media screen and (max-width: 1024px) {
    html.use-grid-css .emb-news-ctas__col {
      width: calc(50% - 40px);
    }
    html.use-grid-css .emb-news-ctas__col .button {
      max-width: unset;
    }
  }
  @media screen and (max-width: 767px) {
    html.use-grid-css .emb-news-ctas__col {
      width: 100%;
      margin: 40px 0 0 0;
    }
    html.use-grid-css .emb-news-ctas__col--text p {
      height: auto;
    }
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;
  margin-top: -15px;
  margin-left: -15px;
}
.categories .categories__item {
  flex: 1 0 200px;
  margin-top: 15px;
  margin-left: 15px;
}
@media (min-width: 430px) {
  .categories .categories__item {
    max-width: calc(50% - 15px);
  }
}
@media (min-width: 645px) {
  .categories .categories__item {
    max-width: calc(33.3333333333% - 15px);
  }
}
@media (min-width: 600px) {
  .categories .categories__item {
    min-width: calc(33.3333333333% - 15px);
  }
}
.categories__item {
  text-decoration: none;
}
.categories__item img {
  max-width: 100%;
  display: block;
}
.categories__caption {
  background-color: #003366;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.categories__caption span {
  color: #ffffff;
  padding: 0 10px 0 0;
}

.news__heading {
  margin: 0 0 25px;
}
.news__heading a {
  text-decoration: none;
  font-size: inherit;
  color: #003366;
  transition: color 0.3s;
}
.news__heading a:hover {
  color: #9f7742;
}
.news__date {
  color: #9f7742;
}
.news__readmore-link {
  text-decoration: underline;
  font-size: inherit;
  color: #003366;
  transition: color 0.3s;
}
.news__readmore-link:hover {
  color: #9f7742;
}
@media screen and (max-width: 768px) {
  .news .news__heading {
    margin: 40px 0 0 0;
  }
}

.pagesidebar {
  display: flex;
}
.pagesidebar__contents {
  flex: 1;
  padding-right: 40px;
}
.pagesidebar__sidebar {
  flex: 0 0 400px;
}
@media screen and (max-width: 768px) {
  .pagesidebar {
    display: block;
  }
  .pagesidebar__contents {
    padding-right: 0;
  }
}

body {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  background-color: #EFEFEF;
}

.contentwrapper, .pagesidebar,
.news,
.newsdetails,
.wh-form {
  max-width: 1120px;
  margin: 0 auto;
}
@media screen and (max-width: 1120px) {
  .contentwrapper, .pagesidebar,
  .news,
  .newsdetails,
  .wh-form {
    padding: 0 25px;
  }
}

h1 {
  color: #003366;
}

.p-404header {
  padding: 40px 0;
  text-align: center;
}

.news,
.newsdetails,
.contentpage,
.wh-form,
.pagesidebar__sidebar {
  margin-top: 50px;
  margin-bottom: 30px;
}