.p-footer {
  background: #424242;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  font-size: 15px;
  line-height: 24px;
}
.p-footer .p-contact {
  margin: 20px 0;
  font-weight: 700;
}
.p-footer .p-contact span {
  padding: 0 10px 0 0;
}
.p-footer .p-contact a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}
.p-footer .p-contact a:hover {
  color: #9f7742;
}
.p-footer .p-footerlinks__link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  padding: 0 10px;
  transition: color 0.3s;
}
.p-footer .p-footerlinks__link:hover {
  color: #9f7742;
}
.p-footer .p-footerlinks .p-logo {
  margin: 15px 0 0 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .p-footer {
    text-align: center;
  }
  .p-contact, .p-footerlinks {
    display: flex;
    flex-direction: column;
  }
}