a {
  color: #003366;
  text-decoration: underline;
  transition: color .3s;
}

a:hover {
  color: #9f7742;
}

body {
  color: #000;
}

h1,
h2,
h3 {
  font-weight: 700;
  letter-spacing: .05em;
  line-height: 100%;
  margin: 0 0 10px;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.25em;
}

p {
  margin: 0 0 10px;
}
