.p-contactinformation__buttons {
  margin-bottom: 80px;
}
.p-contactinformation__buttons .button {
  margin-bottom: 10px;
  min-width: initial;
  width: 100%;
}
.p-contactinformation__map-container {
  position: relative;
}
.p-contactinformation__map-container #google-maps-container {
  width: 100%;
  height: 340px;
}
.p-contactinformation__address-container {
  margin: 40px 0 0 0;
}