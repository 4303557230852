.p-home h1.main-header {
  text-align: center;
  color: #003366;
  padding: 3rem 0;
  font-size: 36px;
  line-height: 45px;
  font-weight: 300;
  letter-spacing: -0.1rem;
}
.p-home .p-content {
  margin: 45px 0;
}
@media screen and (max-width: 768px) {
  .p-home .p-categories {
    flex-direction: column;
  }
  .p-home .p-categories__item {
    margin: 20px 0;
    width: 100%;
  }
}