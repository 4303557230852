.newsitems__categories {
  margin: 20px 0;
}

.newsitems__category {
  display: inline-block;
  text-decoration: none;
  border: 1px solid #000;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 15px;
  transition: background-color 0.3s, color 0.3s;
}
.newsitems__category--selected, .newsitems__category:hover {
  background-color: #0d3579;
  color: #fff;
}