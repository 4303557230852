.header {
  height: 120px;
  background: #424242;
  color: #ffffff;
}
.header .contentwrapper {
  display: flex;
}
.header__logo {
  background: #ffffff;
  width: auto;
  float: left;
  padding: 2rem;
  height: auto;
  border-bottom: 1px solid #BDBDBD;
  z-index: 11;
}
.header__logo img {
  height: 80px;
  width: auto;
}
.header__menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__menuitem {
  padding: 0 1.5rem;
}
.header__menulink {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s;
}
.header__menulink--selected, .header__menulink:hover {
  color: #9f7742;
}